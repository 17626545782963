import React, { useState } from 'react';
import { SearchFacet, SearchFacetValue } from '../Directory/helpers/common-types';
import { SelectedFacet } from './helpers/common-types';
import useMediaQuery from 'react-responsive';
import { isNullOrEmpty } from '../global/helpers/data-helpers';
import { convertSymbols } from '../global/helpers/symbol-helpers';

export interface FacetsProps {
    facets: SearchFacet[];
    filterUpdate: (facetName: string, filterName: string, facetType: string) => void;
    appliedFilters: SelectedFacet[];
    filtersLabelText?: string;
    resetFiltersLabelText?: string;
    resetFilters: () => void;
    noFilterText?: string;
    showFilterCounts?: boolean;
    defaultPlaceholderText?: string;
}

export const Facets = ({ facets, filterUpdate, appliedFilters, filtersLabelText, resetFiltersLabelText, resetFilters, noFilterText, showFilterCounts = false, defaultPlaceholderText }: FacetsProps) => {

    return (
        <div className="facet-container">
            <div className="facet-header">
                <h2 className="h4-style">{filtersLabelText || "Filters"}</h2>
                <button className="facet-reset btn-link" onClick={resetFilters}>{resetFiltersLabelText || "Reset Filters"}</button>
            </div>
            {facets &&
                <div className="facet-list">
                    {facets.map((facet, index) => (
                        <Filters key={facet.name + index} facetCategories={facet.values} facetName={facet.name} filterUpdate={filterUpdate} appliedFilters={appliedFilters} facetDisplay={facet.displayName} showFilterCounts={showFilterCounts} noFilterText={noFilterText} facetType={facet.facetType} selectPlaceholderText={facet.placeholderText || defaultPlaceholderText}></Filters>
                    ))}
                </div>
            }
        </div>
    )
}

export interface FiltersProps {
    facetName: string;
    facetCategories: SearchFacetValue[];
    filterUpdate: (facetName: string, filterName: string, facetType: string) => void;
    appliedFilters: SelectedFacet[];
    facetDisplay: string;
    facetType: string;
    noFilterText?: string;
    showFilterCounts: boolean;
    selectPlaceholderText?: string;
}

const Filters = ({ facetName, facetCategories, filterUpdate, appliedFilters, facetDisplay, facetType, noFilterText, showFilterCounts, selectPlaceholderText }: FiltersProps) => {
    const mobileFilterState = facetName == 'ArticleAuthor' ? false : true
    const [mobileFilterOpen, setMobileFilterOpen] = useState(mobileFilterState);
    const sortAndFilterFacetCategories = () => {
        if (facetName === "PublishDateFacet") {
            return facetCategories.slice().sort((a, b) => b.name.localeCompare(a.name)).filter((a) => a.count > 0);
        }
        else {
            return facetCategories.slice().sort((a, b) => a.displayName.localeCompare(b.displayName)).filter((a) => a.count > 0);
        }
    }
    var filteredFacetCategories = sortAndFilterFacetCategories();
    return (
        <div className="facet-item">
            
                
                {// sort the filters by display name before rendering so that they are always in the same order regardless of the order they are returned from the API
                    facetType === "select" ?
                        <>
                        <h3 className="h6-style">{convertSymbols(facetDisplay)}</h3>
                        {(filteredFacetCategories && filteredFacetCategories.length > 0) ?
                            <SelectFilter filterOptions={filteredFacetCategories} showFilterCounts={showFilterCounts} facetName={facetName} filterUpdate={filterUpdate} appliedFilters={appliedFilters} facetType={facetType} placeHolderText={selectPlaceholderText}></SelectFilter> :
                            <p>{noFilterText}</p>
                        }
                        </>
                        :
                        <>
                            <div className="mobile-filter-accordion">
                                <button className="accordion-trigger facet-item-mobile-trigger" type="button" id={`Accordion_trigger_${facetDisplay.trim().toLowerCase()}`} aria-controls={`Accordion_content_facet_${facetDisplay.trim().toLowerCase() }`} aria-expanded={mobileFilterOpen} onClick={() => setMobileFilterOpen(!mobileFilterOpen)}>
                                <span className="h6-style">{convertSymbols(facetDisplay)}</span>
                                    <i className="accordion-icon-directory ph ph-caret-down"></i>
                                </button>
                            <div className="accordion-content accordion-panel accordion-content-facet" role="region" id={`Accordion_content_facet_${facetDisplay.trim().toLowerCase()}`} aria-hidden={!mobileFilterOpen} aria-labelledby={`Accordion_trigger_${facetDisplay.trim().toLowerCase()}`}>
                                {(filteredFacetCategories && filteredFacetCategories.length > 0) ?
                                    <ul className={`facet-item-list ${mobileFilterOpen ? 'open' : 'closed'}`}>
                                        {filteredFacetCategories && filteredFacetCategories.map((filter, index) => (
                                            <Filter showFilterCounts={showFilterCounts} filterName={filter.name} filterCount={filter.count} displayName={filter.displayName} facetName={facetName} key={filter.name + index} filterUpdate={filterUpdate} facetType={facetType} appliedFilters={appliedFilters} />
                                        ))}
                                    </ul>
                                    :
                                    <p>{noFilterText}</p>
                                }
                                </div> 
                            </div>
                            
                        </>
                    }
        </div>
    ) 
}

export interface FilterProps {
    filterName: string;
    filterCount: number;
    displayName: string;
    facetName: string;
    filterUpdate: (facetName: string, filterName: string, facetType: string) => void;
    appliedFilters: SelectedFacet[];
    facetType: string;
    showFilterCounts: boolean;
}

const Filter = ({ filterName, filterCount, displayName, facetName, filterUpdate, appliedFilters, facetType, showFilterCounts }: FilterProps) => {

    const currentAppliedFilter = appliedFilters.find(({ facetValue }) => facetValue === filterName);
    const facetSelected = !!(currentAppliedFilter && currentAppliedFilter.facetName === facetName);
    
    return (
        <li key={displayName} className="facet-checkbox">
            <input id={`filter_${filterName}`} type="checkbox" checked={facetSelected} onChange={(e) => filterUpdate(facetName, filterName, facetType)} name={`filterby_${filterName}`} />
            <label htmlFor={`filter_${filterName}`}>{convertSymbols(displayName)}{showFilterCounts ? ` (${filterCount})` : ''}</label>
        </li>
    )
}


interface SelectFilterProps {
    filterOptions: SearchFacetValue[];
    facetName: string;
    filterUpdate: (facetName: string, filterName: string, facetType: string) => void;
    appliedFilters: SelectedFacet[];
    facetType: string;
    placeHolderText: string;
    showFilterCounts: boolean;
}
const SelectFilter = ({ filterOptions, facetName, filterUpdate, appliedFilters, facetType, showFilterCounts, placeHolderText }: SelectFilterProps) => {
    const currentAppliedFilter = appliedFilters.find((facet) => facetName === facet.facetName);
    if (isNullOrEmpty(placeHolderText)) {
        placeHolderText = 'Any';
    }
    const facetSelected = currentAppliedFilter ? currentAppliedFilter.facetValue : "";
    return (

        <select className="facet-select" value={facetSelected} onChange={(e) => filterUpdate(facetName, e.target.value, facetType)}>
            <option value="">{placeHolderText}</option>
            {
                filterOptions.map((filter, index) => (
                    <option value={filter.name} key={filter.name + index}>{convertSymbols(filter.displayName)}{showFilterCounts ? ` (${ filter.count })` : ''}</option>
        ))}
    </select>)
}