// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export interface ICarousel {
    width?: number;
    containerClass: string;
    slidesPerView?: number | "auto";
    centeredSlides?: boolean;
    breakpoints?: any;
    spaceBetween?: number;
    navigation?: any;
    pagination?: any;
    autoHeight?: boolean;
    height?: number;
    loop?: boolean;
    autoplayVideo?: boolean;
    pageNumberPreposition?: string;
    watchOverflow?: boolean;
    autoplay?: any;
    
}
const assign = require('lodash.assign');



export class MediaCarousel {
    //these params are exposed to the file you intialize your carousel in, so you can initialize multiple
    //carousel versions across your project or just be able to configure without diving into this file
    params: ICarousel = {
        containerClass: 'swiper',
        slidesPerView: 'auto',
        spaceBetween: 30,
        loop: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            320: {
                spaceBetween: 8,
                slidesPerView: 'auto'
            },
            640: {
                spaceBetween: 32,
                slidesPerView: 'auto'
            },
            800: {
                spaceBetween: 32,
                slidesPerView: 'auto'
            },
            1400: {
                spaceBetween: 32,
                slidesPerView: 'auto'
            },
            1920: {
                spaceBetween: 32,
                slidesPerView: 'auto',
            }
        },
        autoplayVideo: true,
        autoHeight: false,
        autoplay: false,
        centeredSlides: false,
        watchOverflow: true
    };
    carouselElement: any;
    activeSlide: any;
    previousSlide: any;
    activeIndex: any;
    previousIndex: number; // swiper's previousindex implementation doesn't work with loop: true so we need to do it manually
    videos: any;
    carouselId: string;

    constructor(params?: ICarousel) {

        assign(this.params, params);
        Swiper.use([Navigation, Pagination, Autoplay]);
        this.carouselElement = new Swiper(`.${this.params.containerClass}`, {
            slidesPerView: this.params.slidesPerView,
            centeredSlides: this.params.centeredSlides,
            spaceBetween: this.params.spaceBetween,
            breakpoints: this.params.breakpoints,
            navigation: this.params.navigation,
            loop: this.params.loop,
            enabled: true,
            autoHeight: this.params.autoHeight,
            pagination: this.params.pagination,
            watchOverflow: this.params.watchOverflow,
            autoplay: this.params.autoplay
        });
        if (this.carouselElement.el) {
            this.carouselId = this.carouselElement.el.getAttribute('data-unique-id');
        }
        this.videos = {};
        this.initCallback();
    }

    initCallback() {
        this.activeSlide = this.carouselElement.slides[this.carouselElement.activeIndex];
        this.activeIndex = this.carouselElement.activeIndex;
        this.setPageNumbers();
    }

    setPageNumbers() {
        if (this.carouselElement) {
            let totalSlides = this.carouselElement.el.getAttribute('data-total-slides');
            for (let i = 0; i < this.carouselElement.slides.length; i++) {
                let slide = this.carouselElement.slides[i];
                this.reconcileDupeId(slide);
                if (i !== this.activeIndex) {
                    this.disableSlide(slide);
                }
                let slidePageContainer = slide.querySelector('[data-carousel-page]');
                if (slidePageContainer) {
                    let pageString = `${parseInt(slide.getAttribute('data-slide-index')) + 1}${this.params.pageNumberPreposition}${totalSlides}`;
                    slidePageContainer.innerHTML = pageString;
                }
            }

        }
    }

    reconcileDupeId(slide) {
        // check to see if there's 2 slides with same unique ID (when loop is true, slides get duplicated)
        if (slide.querySelector('[data-unique-id]')) {
            let thisId = slide.querySelector('[data-unique-id]').getAttribute('data-unique-id');
            if (this.carouselElement.el.querySelectorAll(`[data-unique-id="${thisId}"]`).length > 1) {
                let dupes = Array.prototype.slice.call(this.carouselElement.el.querySelectorAll(`[data-unique-id="${thisId}"]`));
                dupes.forEach((dupe, index) => {
                    dupe.setAttribute('data-unique-id', `${thisId}-${index}`);
                })
            }
        }
    }

    handleSlide() {
        if (this.carouselElement) {
            this.previousSlide = this.activeSlide;
            this.previousIndex = this.activeIndex;
            this.activeSlide = this.carouselElement.slides[this.carouselElement.activeIndex];
            this.activeIndex = this.carouselElement.activeIndex;
            this.disableSlide(this.previousSlide);
            this.enableSlide(this.activeSlide);
        }
    }

    disableSlide(slide) {
        // disable tabbing on tabbable content by default to stop trapping keyboard, and hide from screenreader users
        let tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            item.tabIndex = -1;
        });
        slide.setAttribute('aria-hidden', 'true');
    }

    enableSlide(slide) {
        // enable tabbing on active slide and show to screenreader users
        let tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            item.tabIndex = 0;
        });
        slide.setAttribute('aria-hidden', 'false');
    }

}