export type SelectedFacet = {
    facetName: string,
    facetValue: string,
}
export interface IArticle {
    authorName: string;
    categories: string[];
    description: string;
    imageUrl: string;
    isHighlighted: boolean;
    publishDate: string;
    sponsorLogoUrl: string;
    sponsorName: string;
    sponsorUrl: string;
    title: string;
    url: string;
}
export type ArticleLandingApiParameters = {
    limit: number,
    skip: number,
    siteId: number,
    facets: SelectedFacet[] | undefined,
    orderBy?: number,
    direction?: number
}
export const baseApiParameters: ArticleLandingApiParameters = {
    limit: 10,
    skip: 0,
    siteId: 0,
    facets: [],
    orderBy: null,
    direction: null
}