import Masonry from 'masonry-layout';
const maxNumberOfItems = 20;

const itemNumberIncrement = 10;
export function downloadBlock(downloadBlockContainers) {

    downloadBlockContainers.forEach((downloadBlockContainer) => {
        const masonryContainer = downloadBlockContainer.querySelector('[data-masonry-container]');
        const filters = downloadBlockContainer.querySelectorAll('[data-download-filter-item]');
        const masonryItems = downloadBlockContainer.querySelectorAll('[data-masonry-item]');
        const loadMoreButton = downloadBlockContainer.querySelector('[data-download-more]');
        
        const tabSetList = document.querySelectorAll('[data-tabs-container]');


            var msnry = new Masonry(masonryContainer, {
                // options
                itemSelector: '[data-masonry-item]',
                columnWidth: '[data-masonry-sizer]',
                percentPosition: true,
                gutter: '[data-masonry-gutter]',
                horizontalOrder: true,
                initLayout:false
            });
        const msnryData = msnry.getItemElements();
        const overflowItems = msnryData.slice(maxNumberOfItems);
        msnry.remove(overflowItems);
        msnry.layout();
        filters.forEach((filter) => {
            filter.addEventListener('click', (e) => {
                toggleFilteredElements(msnry, masonryItems, filter, filters, masonryContainer, loadMoreButton);
                msnry.layout();
            });
        });
        loadMoreButton.addEventListener('click', (e) => {
            loadMoreItems(filters, msnryData, msnry, loadMoreButton);
        });
        if (overflowItems.length > 0) {
            loadMoreButton.style.display = 'block';
        }
        if (tabSetList.length > 0) {
            tabSetList.forEach((tabSet) => {
                if (tabSet.contains(masonryContainer)) {
                    
                    tabSet.querySelectorAll('[data-tab-button]').forEach((tab) => {
                        let tabContainer = tabSet.querySelector("#" + tab.getAttribute('aria-controls'))
                        var observer = new MutationObserver(function (mutations) {
                            mutations.forEach(function (mutation) {
                                let nodeElement = mutation.target as Element;
                                if (mutation.type === "attributes" && mutation.attributeName === "hidden" && nodeElement.getAttribute('hidden') != "hidden") {
                                    msnry.layout();
                                }
                            });
                        });
                        
                        if (tabContainer && tabContainer.contains(masonryContainer)) {
                            observer.observe(tabContainer, {
                                attributeFilter: ["hidden"]
                            });
                        }
                    });
                }
            });
        }

    });
    
}



function toggleFilteredElements(masonryElement, masonryItems, selectedFilter, filters, masonryContainer, loadMoreButton) {
    // remove all filtered elements
    let filterState = getCurrentActiveFilters(filters);
    let filteredItems = [];
    let currentActiveItems = masonryElement.getItemElements();
    if (filterState.length > 0 && filterState.includes(selectedFilter)) {

        filterState = filterState.filter((filter) => {
            return filter !== selectedFilter;
        });

        selectedFilter.setAttribute('data-filter-state', 'inactive');
    }
    else {
        filterState.push(selectedFilter);
        selectedFilter.setAttribute('data-filter-state', 'active');
        
    }
    filteredItems = getFilteredItems(filterState, masonryItems);
    let totalAddedItems = [...filteredItems];
    let removedItems = masonryItems;
    masonryElement.remove(removedItems);
    setTimeout(() => {
        if ((currentActiveItems.length - removedItems.length) < maxNumberOfItems) {
            addItemsToMasonry(masonryElement, totalAddedItems, masonryContainer, currentActiveItems, maxNumberOfItems, loadMoreButton);
        }
        masonryElement.layout();
    }, 500);
    

}

function addItemsToMasonry(masonryElement, filteredItemsNotShown, masonryContainer, currentActiveItems, numberItemsToAdd, loadMoreButton) {
    let i = 0;
    let addedItems = [];

    filteredItemsNotShown.forEach((item) => {
        
        if (i < numberItemsToAdd) {
            masonryContainer.appendChild(item);
            addedItems.push(item);
        }
        i++
    });
    masonryElement.addItems(addedItems);
    if (filteredItemsNotShown.length <= numberItemsToAdd) {
        loadMoreButton.style.display = 'none';
    }
    else {
        loadMoreButton.style.display = 'block';
    }
}
function getCurrentActiveFilters(filters) {
    const activeFilters = [];
    filters.forEach((filter) => {
        filter.getAttribute('data-filter-state');
        if (filter.getAttribute('data-filter-state') == 'active') {
            activeFilters.push(filter);
        }
    });
    return activeFilters;
}

function loadMoreItems(filters, masonryItems, masonryElement, loadMoreButton) {
    let currentActiveItems = masonryElement.getItemElements();
    let filteredTotalItems = getFilteredItems(getCurrentActiveFilters(filters), masonryItems);
    let filteredItemsNotShown = filteredTotalItems.filter((item) => { return !currentActiveItems.includes(item) });
    addItemsToMasonry(masonryElement, filteredItemsNotShown, masonryElement.element, currentActiveItems, itemNumberIncrement, loadMoreButton);
    masonryElement.layout();
}

function getFilteredItems(selectedFilters, masonryItems) {
    const filteredItems = [];
    const selectedTerms = [];
    selectedFilters.forEach((filter) => {
        selectedTerms.push(filter.getAttribute('data-download-filter-item'));
    })
    masonryItems.forEach((item) => {
            if ( !selectedTerms.includes(item.dataset.fileType)) {
                filteredItems.push(item);
            }
    });
    return filteredItems;

}