import React, { forwardRef } from "react";
import { EventData } from "../../eventsCalendar/features/api/api";

interface SearchInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
    onEnter?: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const SearchInput = forwardRef(({
  placeholder,
  value,
  onChange,
  onEnter,
    onKeyDown = (e) => {
        if (e.key === "Enter" && onEnter) {
            onEnter((e.target as HTMLInputElement).value);
        }
    },
    onBlur = (e) => { },
   onFocus = (e) => { }
}: SearchInputProps, ref: React.RefObject<HTMLInputElement | null>) => {
  return (
      <input
          className="search-input"
          type="text"
          placeholder={placeholder}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => {
              onKeyDown(e);
          }}
          ref={ref}
          onBlur={(e) => { onBlur(e) }}
          onFocus={(e) => { onFocus(e) }}
    />
  );
});

export default SearchInput;
