import { SelectedFacet } from "../../reactComponents/helpers/common-types"

export type SearchFacet = {
    name: string,
    displayName: string,
    facetType: string,
    values: SearchFacetValue[],
    placeholderText: string
}

export type SearchFacetValue = {
    name: string,
    displayName: string,
    count: number
}

export type DirectoryPageCard = {
    id: number,
    title: DirectoryPageCardItem,
    primaryLeftData: DirectoryPageCardItem[],
    secondaryFullWidth: DirectoryPageCardItem[],
    secondaryLeftData: DirectoryPageCardItem[],
    secondaryRightData: DirectoryPageCardItem[],
    image: DirectoryPageCardItem
    isHighlighted: boolean
    score: number
}

export type DirectoryPageCardItem = {
    fieldTitle: string,
    fieldTitleDisplay: string,
    fieldValue: DirectoryListingCellValue,
    dataType: string,
    listDelimiter: string
}

export type DirectoryListingCellValue = {
    cellTextValue: string,
    cellLinkAddress: string,
    cellImageAddress: string,
}

export type DirectoryApiParameters = {
    limit: number,
    skip: number,
    pageId: number,
    facets: SelectedFacet[] | undefined,
    orderBy?: number,
    direction?: number
    searchTerm?: string
    useKeywordSearch: boolean
}

export const baseApiParameters: DirectoryApiParameters = {
    limit: 10,
    skip: 0,
    pageId: 0,
    facets: [],
    orderBy: null,
    direction: null,
    searchTerm: null,
    useKeywordSearch: false
}

export type DirectoryResponseData = {
    total: number,
    results: DirectoryPageCard[],
    facets: SearchFacet[],
    sortOptions: SortOrderOption[]
}

export type SortOrderOption = {
    displayName: string,
    column: number,
    direction: number,
    isSelected: boolean
}

export class SelectOption {
    value: string;
    label: string;

    constructor(value: string, label: string) {
        this.value = value;
        this.label = label;
    }
}