import { SelectedFacet } from "../helpers/common-types";
import {
  isNullOrUndefined,
  isNullOrEmpty,
} from "../../global/helpers/data-helpers";
import { SearchResultsState } from "../features/search/search-slice";

type QueryStringState = {
  query: string;
  skip?: number;
  facets?: SelectedFacet[];
  sortBy?: string;
  context?: string;
};

export const getStateFromQueryString = () => {
  const urlParams = new URLSearchParams(window.location.search);

  let filters: SelectedFacet[] = [];
  let limit = (window as any).bootstrap?.pageSize as number;

  urlParams.forEach((value, key) => {
    if (key !== "q" && key !== "page" && key !== "sortBy") {
        filters.push({
            facetName: decodeURIComponent(key),
            facetValue: decodeURIComponent(value),
      });
    }
  });
  return {
    skip: urlParams.has("page")
      ? (parseInt(urlParams.get("page") as string) - 1) * limit
      : 0,
    sortBy: urlParams.get("sortBy") || "Relevance",
      query: urlParams.get("q")? decodeURIComponent(urlParams.get("q")) : "",
    facets: filters,
    context: urlParams.get("context") || "",
  } as QueryStringState;
};

export const writeStateToQueryString = ({
  apiParams,
  currentAppliedFilters,
}: SearchResultsState) => {
  const baseUrl = new URL(window.location.origin + window.location.pathname);
  let page = apiParams.skip / apiParams.limit + 1;
  if (page) {
    baseUrl.searchParams.set("page", page.toString());
  }
  if (currentAppliedFilters) {
    currentAppliedFilters.forEach((filter, index) => {
      if (index === 0) {
          baseUrl.searchParams.set(encodeURIComponent(filter.facetName), encodeURIComponent(filter.facetValue.trim()));
      } else {
          baseUrl.searchParams.append(encodeURIComponent(filter.facetName), encodeURIComponent(filter.facetValue.trim()));
      }
    });
  }
  if (!isNullOrEmpty(apiParams.sortBy)) {
    baseUrl.searchParams.set("sortBy", apiParams.sortBy);
  }
  if (!isNullOrEmpty(apiParams.queryText)) {
      baseUrl.searchParams.set("q", encodeURIComponent(apiParams.queryText.trim()));
  }
  if (!isNullOrEmpty(apiParams.context)) {
    baseUrl.searchParams.set("context", apiParams.context);
  }
  window.history.pushState({}, "", baseUrl.toString());
};
