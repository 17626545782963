import Player from '@vimeo/player';

var debounce = require('lodash.debounce');
var assign = require('lodash.assign');

var options = {
    url: '',
    autoplay: true,
    controls: false,
    background: true,
    muted: true
};

//remove comments for control over sound in hero banners
export function heroBlock(heroBlocks) {
    heroBlocks.forEach(heroBlock => {
        const vimeoContainer = heroBlock.querySelector('[data-vimeo-hero-container]');
        options.url = vimeoContainer.getAttribute('url');
        const videoPlayer = new Player(vimeoContainer, options);

        const videoPlayButton = heroBlock.querySelector('[data-vimeo-play]');
        //const videoMuteButton = heroBlock.querySelector('[data-vimeo-mute]');

        videoPlayButton.addEventListener('click', () => {
            playOrPauseVideo(videoPlayer, videoPlayButton);
            
        });

        /*videoMuteButton.addEventListener('click', () => {
            muteOrUnmuteVideo(videoPlayer, videoMuteButton);
       });
        */
        videoPlayer.on('loaded', function (volume) {
            videoPlayer.setVolume(0).then(function () {
                // The volume is set
                //videoMuteButton.classList.remove('unmuted');
                //videoMuteButton.classList.add('muted');
            }).catch(function (error) {
                switch (error.name) {
                    case 'RangeError':
                        // The volume is less than 0 or greater than 1
                        break;
                    default:
                        // Some other error occurred
                        break;
                }
            });  
        });

    });
}
/*
function muteOrUnmuteVideo(videoPlayer, videoMuteButton) {
    videoPlayer.getVolume().then(function (volume) {
        // `volume` indicates the volume level of the player

        if (volume == 0) {
            videoPlayer.setVolume(1).then(function () {
                // The volume is set
                videoMuteButton.classList.remove('muted');
                videoMuteButton.classList.add('unmuted');
            }).catch(function (error) {
                switch (error.name) {
                    case 'RangeError':
                        // The volume is less than 0 or greater than 1
                        break;
                    default:
                        // Some other error occurred
                        break;
                }
            });
        }
        else {
            videoPlayer.setVolume(0).then(function () {
                // The volume is set
                videoMuteButton.classList.remove('unmuted');
                videoMuteButton.classList.add('muted');
            }).catch(function (error) {
                switch (error.name) {
                    case 'RangeError':
                        // The volume is less than 0 or greater than 1
                        break;
                    default:
                        // Some other error occurred
                        break;
                }
            });  
        }
    });
    
}*/

function playOrPauseVideo(videoPlayer, videoPlayButton) {
    videoPlayer.getPaused().then(function (paused) {
        // `paused` indicates whether the player is paused
        if (paused) {
            videoPlayer.play().then(() => {
                //the video is playing
                videoPlayButton.classList.remove('paused');
                videoPlayButton.classList.add('playing');
            }).catch(function (error) {
                switch (error.name) {
                    case 'PasswordError':
                        // The video is password-protected
                        break;
                    case 'PrivacyError':
                        // The video is private
                        break;
                    default:
                        // Some other error occurred
                        break;
                }
            });

        }
        else {
            videoPlayer.pause().then(function () {
                // The video is paused
                videoPlayButton.classList.remove('playing');
                videoPlayButton.classList.add('paused');
            }).catch(function (error) {
                switch (error.name) {
                    case 'PasswordError':
                        // The video is password-protected
                        break;
                    case 'PrivacyError':
                        // The video is private
                        break;
                    default:
                        // Some other error occurred
                        break;
                }
            });
        }
    });
    
}