import React, {forwardRef, createRef } from "react";
import { AutoCompleteApiResponseData } from "../helpers/common-types";

interface AutoCompleteResultsProps {
  results: AutoCompleteApiResponseData;
  onClick: (value: string) => void;
    focusCount: number;
    onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
}

const AutoCompleteResults = forwardRef(({
  results,
  onClick,
    focusCount,
    onKeyDown
}: AutoCompleteResultsProps, ref: any) => {

    if (results.length > 0 && ref.current.length !== results.length) {
        ref.current = Array(results.length).fill([]).map((_, i) => ref.current[i] || createRef());
    }
    return results.length === 0 ? null : (
        <ul data-search-autocomplete-results>
            {results.map((result, index) => (
                <li data-search-autocomplete-results key={index} >
                    <button data-search-autocomplete-results className={`un-button ${index == focusCount ? 'focus' : ''}`} onKeyDown={(e) => { onKeyDown(e) }} type="button" ref={ref.current[index]} onClick={() => onClick(result)}> {result}</button>
        </li>
      ))}
    </ul>
  );
});

export default AutoCompleteResults;
