import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedFacet } from "../../helpers/common-types";
import { baseSearchApiParameters } from "../../helpers/common-types";
import {
  getStateFromQueryString,
  writeStateToQueryString,
} from "../../app/query-string";

export interface SearchResultsState {
  apiParams: {
    limit: number;
    pageId: number;
    skip: number;
    facets: SelectedFacet[];
    queryText: string;
    sortBy: string;
    siteId?: string;
    context?: string;
  };
  currentAppliedFilters: SelectedFacet[];
}

let limit = (window as any).bootstrap?.pageSize as number;
let pageId = (window as any).bootstrap?.pageId as number;
let siteId = (window as any).bootstrap?.currentSiteId as string;
const blankFilterState: SelectedFacet[] = [];

const { query, skip, facets, sortBy, context } = getStateFromQueryString();

const initialState: SearchResultsState = {
  apiParams: {
    ...baseSearchApiParameters,
    limit,
    pageId,
    queryText: query,
    skip,
    facets,
    sortBy,
    context,
    siteId,
  },
  currentAppliedFilters: facets || blankFilterState,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSearchQuery(state, action: PayloadAction<{ queryText: string }>) {
      state.apiParams.queryText = action.payload.queryText;
      state.apiParams.skip = 0;
      writeStateToQueryString(state);
    },
    updateSkip(state, action: PayloadAction<{ newSkip: number }>) {
      state.apiParams.skip = action.payload.newSkip;
      writeStateToQueryString(state);
    },
    updateSort(state, action: PayloadAction<{ sortOption: string }>) {
      state.apiParams.sortBy = action.payload.sortOption;
      state.apiParams.skip = 0;
      writeStateToQueryString(state);
    },
    updateContext(state, action: PayloadAction<{ context: string }>) {
      state.apiParams.context = action.payload.context;
      state.apiParams.skip = 0;
      state.currentAppliedFilters = blankFilterState;
      state.apiParams.facets = blankFilterState;
      writeStateToQueryString(state);
    },
    updateFilter(
      state,
      action: PayloadAction<{ facetName: string; facetValue: string }>
    ) {
      const facetData = {
        facetName: action.payload.facetName,
        facetValue: action.payload.facetValue,
      };

      const selectedFacet = state.currentAppliedFilters.find(
        (filter) => filter.facetValue === facetData.facetValue
      );

      if (selectedFacet) {
        state.currentAppliedFilters = state.currentAppliedFilters.filter(
          (filter) => filter.facetValue !== facetData.facetValue
        );
      } else {
        state.currentAppliedFilters.push(facetData);
      }

      state.apiParams.facets = state.currentAppliedFilters;
      state.apiParams.skip = 0;
      writeStateToQueryString(state);
    },
    resetFilters(state) {
      state.currentAppliedFilters = blankFilterState;
      state.apiParams.facets = blankFilterState;
      state.apiParams.skip = 0;
      state.apiParams.queryText = "";
      writeStateToQueryString(state);
    },
    matchStateToQueryString(state) {
      const { skip, sortBy, facets, context } = getStateFromQueryString();
      state.apiParams.skip = skip;
      state.apiParams.sortBy = sortBy;
      state.apiParams.context = context;
      state.apiParams.facets = facets || blankFilterState;
      state.currentAppliedFilters = facets || blankFilterState;
    },
  },
});

export const {
  updateSearchQuery,
  updateFilter,
  updateSkip,
  updateSort,
  matchStateToQueryString,
  resetFilters,
  updateContext,
} = searchSlice.actions;
export default searchSlice.reducer;
