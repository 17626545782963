/* Implementation of the presentation of the audio player */

export function audioplayer(audioPlayers) {
    audioPlayers.forEach(audioPlayerContainer => {
        const seekSlider = audioPlayerContainer.querySelector('[data-seek-slider]') as HTMLInputElement;
        const volumeSlider = audioPlayerContainer.querySelector('[data-volume-slider]') as HTMLInputElement;
        const muteIconContainer = audioPlayerContainer.querySelector('[data-mute-icon]') as HTMLButtonElement;
        const playIconContainer = audioPlayerContainer.querySelector('[data-play-icon]') as HTMLButtonElement;
        let playState = 'play';
        let muteState = 'unmute';


        playIconContainer.addEventListener('click', () => {
            if (playState === 'play') {
                audio.play();
                requestAnimationFrame(whilePlaying);
                playState = 'pause';
                playPause(playIconContainer);
            } else {
                audio.pause();
                cancelAnimationFrame(raf);
                playState = 'play';
                playPause(playIconContainer);
            }
        });

        muteIconContainer.addEventListener('click', () => {
            if (muteState === 'unmute') {
                audio.muted = true;
                muteState = 'mute';
                muteUnmute(muteIconContainer);
            } else {
                audio.muted = false;
                muteState = 'unmute';
                muteUnmute(muteIconContainer);
            }
        });

        const showRangeProgress = (rangeInput) => {
            if (rangeInput === seekSlider) audioPlayerContainer.style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%');
            else audioPlayerContainer.style.setProperty('--volume-before-width', rangeInput.value / rangeInput.max * 100 + '%');
        }

        seekSlider.addEventListener('input', (e) => {
            showRangeProgress(e.target);
        });
        volumeSlider.addEventListener('input', (e) => {
            showRangeProgress(e.target);
        });





        /* Implementation of the functionality of the audio player */

        const audio = audioPlayerContainer.querySelector('audio') as HTMLAudioElement;
        const durationContainer = audioPlayerContainer.querySelector('[data-duration]');
        const currentTimeContainer = audioPlayerContainer.querySelector('[data-current-time]');
        const outputContainer = audioPlayerContainer.querySelector('[data-volume-output]');
        let raf = null;

        const calculateTime = (secs) => {
            const minutes = Math.floor(secs / 60);
            const seconds = Math.floor(secs % 60);
            const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${minutes}:${returnedSeconds}`;
        }

        const displayDuration = () => {
            durationContainer.textContent = calculateTime(audio.duration);
        }

        const setSliderMax = () => {
            seekSlider.max = `${Math.floor(audio.duration)}`;
        }

        const displayBufferedAmount = () => {
            if (audio.buffered.length !== 0) {
                let bufferedAmount = Math.floor(audio.buffered.end(audio.buffered.length - 1));
                    audioPlayerContainer.style.setProperty('--buffered-width', `${(bufferedAmount / parseFloat(seekSlider.max)) * 100}%`);
            }
        }

        const whilePlaying = () => {
            seekSlider.value = `${Math.floor(audio.currentTime)}`;
            currentTimeContainer.textContent = calculateTime(seekSlider.value);
            audioPlayerContainer.style.setProperty('--seek-before-width', `${parseFloat(seekSlider.value) / parseFloat(seekSlider.max) * 100}%`);
            raf = requestAnimationFrame(whilePlaying);
        }

        if (audio.readyState === 4) {
            displayDuration();
            setSliderMax();
            displayBufferedAmount();
        } else {
            audio.addEventListener('loadeddata', () => {
                if (audio.readyState === 4) {
                    displayDuration();
                    setSliderMax();
                    displayBufferedAmount();
                }
            });
        }

        audio.addEventListener('progress', displayBufferedAmount);

        seekSlider.addEventListener('input', () => {
            currentTimeContainer.textContent = calculateTime(seekSlider.value);
            if (!audio.paused) {
                cancelAnimationFrame(raf);
            }
        });

        seekSlider.addEventListener('change', () => {
            audio.currentTime = parseFloat(seekSlider.value);
            if (!audio.paused) {
                requestAnimationFrame(whilePlaying);
            }
        });

        volumeSlider.addEventListener('input', (e) => {
            let eventTarget = e.target as HTMLInputElement;
            const value = eventTarget.value;

            outputContainer.textContent = value;
            audio.volume = parseFloat(value) / 100;
        });




        /* Implementation of the Media Session API */
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: 'ARA Site Audio',
                artist: 'unknown',
                album: 'unknown',
                artwork: [
                    /*{ src: '', sizes: '96x96', type: 'image/png' },
                    { src: '', sizes: '128x128', type: 'image/png' },
                    { src: '', sizes: '192x192', type: 'image/png' },
                    { src: '', sizes: '256x256', type: 'image/png' },
                    { src: '', sizes: '384x384', type: 'image/png' },
                    { src: '', sizes: '512x512', type: 'image/png' }*/
                ]
            });
            navigator.mediaSession.setActionHandler('play', () => {
                if (playState === 'play') {
                    audio.play();
                    requestAnimationFrame(whilePlaying);
                    playState = 'pause';
                    playPause(playIconContainer);
                } else {
                    audio.pause();
                    cancelAnimationFrame(raf);
                    playState = 'play';
                    playPause(playIconContainer);
                }
            });
            navigator.mediaSession.setActionHandler('pause', () => {
                if (playState === 'play') {
                    audio.play();
                    requestAnimationFrame(whilePlaying);
                    playState = 'pause';
                    playPause(playIconContainer);
                } else {
                    audio.pause();
                    cancelAnimationFrame(raf);
                    playState = 'play';
                    playPause(playIconContainer);
                }
            });
            navigator.mediaSession.setActionHandler('seekbackward', (details) => {
                audio.currentTime = audio.currentTime - (details.seekOffset || 10);
            });
            navigator.mediaSession.setActionHandler('seekforward', (details) => {
                audio.currentTime = audio.currentTime + (details.seekOffset || 10);
            });
            navigator.mediaSession.setActionHandler('seekto', (details) => {
                if (details.fastSeek && 'fastSeek' in audio) {
                    audio.fastSeek(details.seekTime);
                    return;
                }
                audio.currentTime = details.seekTime;
            });
            navigator.mediaSession.setActionHandler('stop', () => {
                audio.currentTime = 0;
                seekSlider.value = '0';
                audioPlayerContainer.style.setProperty('--seek-before-width', '0%');
                currentTimeContainer.textContent = '0:00';
                if (playState === 'pause') {
                    cancelAnimationFrame(raf);
                    playState = 'play';
                    playPause(playIconContainer);
                }
            });
        }
    })
}

function playPause(playButton) {
    playButton.classList.toggle('pause');
    playButton.classList.toggle('play');
}
function muteUnmute(muteButton) {
    muteButton.classList.toggle('muted');
    muteButton.classList.toggle('unmuted');
}