import React from 'react';

export interface PaginationProps {
    totalItems: number;
    pageSize: number;
    currentPage: number;
    numberOfPageButtons: number; // should be an odd number
    updateSkip: (newSkip: number) => void;
}

export const Pagination = ({ totalItems, pageSize, currentPage, updateSkip, numberOfPageButtons, ...rest }: PaginationProps) => {
    if (totalItems === -1) {
        totalItems = 10000;
    }
    const totalPages = Math.ceil(totalItems / pageSize);
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    // The number of buttons to show needs to be odd so that we can balance the number of buttons on either side of the current page
    var numberOfButtonsToShow = numberOfPageButtons;
    if (numberOfButtonsToShow % 2 === 0) {
        numberOfButtonsToShow += 1;
    }
    
    const handlePreviousPage = () => {
        if (!isFirstPage) {
            // Subtract 2 because the currentPage is 1-based and the skip is 0-based (i.e. index = currentPage - 1, so index - 1 = currentPage - 2)
            updateSkip((currentPage - 2 ) * pageSize);
        }
    };

    const goToPage = (pageNumber: number) => {
        updateSkip((pageNumber - 1) * pageSize);
    }

    const handleNextPage = () => {
        if (!isLastPage) {
            // We don't need to add anything to currentPage because the skip is 0-based and currentPage is 1-based
            updateSkip(currentPage * pageSize);
        }
    };

    const isEllipsis = (pageNumber: number, currentPage: number, numberOfPages: number): boolean => {
        var numberAboveBelow = Math.floor((numberOfButtonsToShow - 2) / 2);
        if (pageNumber === 1 || // always show the first page
            pageNumber === totalPages || // always show the last page
            pageNumber === currentPage || // always show the current page
            // if the current page + padding is less than max to show and page is less than max
            (currentPage + numberAboveBelow < numberOfButtonsToShow && pageNumber < numberOfButtonsToShow) || 
            // if the current page is within the buffer of the last page and the page is within the max to show
            // we have to subtract 1 because the first page is always shown
            (currentPage >= totalPages - numberAboveBelow && totalPages - pageNumber < numberOfButtonsToShow - 1) ||
            // if the page is within the the number above and below the current page
            (pageNumber >= currentPage - numberAboveBelow && pageNumber <= currentPage + numberAboveBelow)
            ) 
        {
            return false;
        }
        // otherwise, should be an ellipsis
        return true;
    }

    return (
        <div className="pagination">
        <div className="pagination-buttons">
            <button className="btn btn-primary pagination-first" onClick={() => goToPage(1)} disabled={isFirstPage} key={"pagination-first"}>
                <i className="ph ph-arrow-left"></i>
            </button>
            <button className="btn btn-primary pagination-previous" onClick={handlePreviousPage} disabled={isFirstPage} key={"pagination-previous"}>
                <i className="ph ph-caret-left"></i>
                </button>
            </div>
            <div className="pagination-pages">
            {[...Array(totalPages)].map((x, i) => {
                var index = i + 1
                if (isEllipsis(index, currentPage, totalPages)) {
                    // if is a hidden page and there isn't already an ellipsis before it, show an ellipsis
                    if (index > 1 && !isEllipsis(index-1, currentPage, totalPages)) {
                        return <span key={`ellipsis-${index}`}>...</span>
                    }
                    return null;
                }
                if (index === currentPage) {
                    return <button className="btn btn-primary pagination-page-button selected" disabled={true} key={"pagination" + index}>{index}</button>
                }
                return <button className="btn btn-primary pagination-page-button" onClick={() => goToPage(index)} key={"pagination" + index}>{index}</button>
            })}
            </div>
            <div className="pagination-buttons">
            <button className="btn btn-primary pagination-next" onClick={handleNextPage} disabled={isLastPage} key={"pagination-next"}>
                <i className="ph ph-caret-right"></i>
            </button>
            <button className="btn btn-primary pagination-last" onClick={() => goToPage(totalPages)} disabled={isLastPage} key={"pagination-last"}>
                <i className="ph ph-arrow-right"></i>
                </button>
            </div>
        </div>
    );
};
