import React, { ChangeEvent, useEffect, useState } from 'react';
import { SelectOption } from '../helpers/common-types';

export interface DirectorySortProps {
    sortLabel?: string;
    sortOptions: SelectOption[];
    currentSort: string;
    updateSort: (value: string) => void;
}

export const SortSelector = (props: DirectorySortProps) => {
    const { sortLabel, sortOptions, updateSort, currentSort } = props;
    if (!sortOptions || sortOptions.length === 0) {
        return null;
    }
    const updateSortValue = (event: ChangeEvent<HTMLSelectElement>) => {
        updateSort(event.target.value);
    }

    return (
        <div className="sort-container">
            {sortLabel && <span className="sort-label bold">{sortLabel}</span>}
            <select className="sort-select" onChange={updateSortValue} value={currentSort}>
                {sortOptions.map((option, index) => {
                        return <option key={"sort-" + index} value={option.value}>{option.label}</option>
                    })}
            </select>
        </div>
    )
}