import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  results: [""],
};

const autocompleteSlice = createSlice({
  name: "autocomplete",
  initialState,
  reducers: {
    loadAutoComplete(state, action: PayloadAction<string[]>) {
      state.results = action.payload;
    },
  },
});

export const { loadAutoComplete } = autocompleteSlice.actions;
export default autocompleteSlice.reducer;
