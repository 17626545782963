import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  SearchApiParameters,
  SearchApiResponseData,
  AutoCompleteApiParameters,
  AutoCompleteApiResponseData,
  baseAutoCompleteApiParameters,
} from "../../helpers/common-types";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/v1" }),
  endpoints: (builder) => {
    return {
      search: builder.query<SearchApiResponseData, SearchApiParameters>({
        query: (body) => ({
          url: "/search",
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body,
        }),
      }),
      autoComplete: builder.query<
        AutoCompleteApiResponseData,
        AutoCompleteApiParameters
      >({
        query: (params) => ({
          url: "/search/autocomplete",
          method: "GET",
          params: params || baseAutoCompleteApiParameters,
        }),
      }),
    };
  },
});

export const { useSearchQuery, useAutoCompleteQuery } = apiSlice;
