
export function upcomingEvents(upcomingEventsContainers) {

    upcomingEventsContainers.forEach((upcomingEventsContainer) => {
        const filters = upcomingEventsContainer.querySelectorAll('[data-filter-item]');
        filters.forEach((filter) => {
            filter.addEventListener('click', (e) => {
                toggleFilteredElements(upcomingEventsContainer, filter , filters);
            });
        });
    });
    
}

function toggleFilteredElements(upcomingEventsContainer, selectedFilter, filters) {
    // remove all filtered elements
    let eventItems = upcomingEventsContainer.querySelectorAll('[data-event-type]');
    let filterState = getCurrentActiveFilters(filters);
    let filteredItems = [];
    if (filterState.length > 0 && filterState.includes(selectedFilter)) {

        filterState = filterState.filter((filter) => {
            return filter !== selectedFilter;
        });

        selectedFilter.setAttribute('data-filter-state', 'inactive');
    }
    else {
        filterState.push(selectedFilter);
        selectedFilter.setAttribute('data-filter-state', 'active');
    }
    filteredItems = getFilteredItems(filterState, eventItems);

    eventItems.forEach((item) => {
        if (filteredItems.includes(item)) {
            item.classList.add('show');
            item.classList.remove('hide');
        }
        else {
            item.classList.add('hide');
            item.classList.remove('show');
        }
        
    });

}

function getCurrentActiveFilters(filters) {
    const activeFilters = [];
    filters.forEach((filter) => {
        filter.getAttribute('data-filter-state');
        if (filter.getAttribute('data-filter-state') == 'active') {
            activeFilters.push(filter);
        }
    });
    return activeFilters;
}

function getFilteredItems(selectedFilters, upcomingEvents) {
    const filteredItems = [];
    const selectedTerms = [];
    selectedFilters.forEach((filter) => {
        selectedTerms.push(filter.getAttribute('data-filter-item'));
    })
    upcomingEvents.forEach((item) => {
            if ( !selectedTerms.includes(item.dataset.eventType)) {
                filteredItems.push(item);
            }
    });
    return filteredItems;

}