import React, { forwardRef } from "react";
import { convertSymbols } from "../global/helpers/symbol-helpers";
interface IAlertProps {
    isError: boolean,
    textContent: string

}

export const Alert = forwardRef<HTMLDivElement, IAlertProps>(({ isError, textContent }, ref) => {

    return (
        <div className={`alert-message ${isError ? "alert-message-error" : "alert-message-warning"}`} ref={ref} >
            <div className="alert-message-icon">
                {isError ?
                    <svg className="form-element-error-icon">
                        <use xlinkHref="#icon-error"></use>
                    </svg> :
                    <svg className="form-element-error-icon">
                        <use xlinkHref="#icon-warning"></use>
                    </svg>
                }
            </div>
            <div className="alert-message-text" dangerouslySetInnerHTML={{ __html: convertSymbols(textContent) }} />
        </div>

    );
});