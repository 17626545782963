import React from "react";
import AutoComplete from "../searchResults/components/autocomplete";

const App = () => {
  const navigateToSearchResultsPage = (value: string) => {
    const searchPageUrl = (window as any).searchBar?.searchPageUrl;

    if (!searchPageUrl) {
      console.error("Search Page URL is not defined");
      return;
    }

    window.location.href = `${searchPageUrl}?q=${value}`;
  };
  return (
    <>
      <AutoComplete
        onSearch={(value) => {
          navigateToSearchResultsPage(value);
        }}
      />
    </>
  );
};

export default App;
