import A11yDialog from 'a11y-dialog';

export class Modal {
    modal: any;
    modalElement: HTMLElement;

    constructor(modalElement: HTMLElement) {
        this.modal = new A11yDialog(modalElement);
        this.modalElement = modalElement;
        
        this.modal.on('show', () => {
            document.body.style.overflow = 'hidden';
        });
        this.modal.on('hide', () => {
            document.body.style.overflow = '';

        });
        if (this.modalElement.getAttribute('data-modal-default-open')) {
            this.modal.show();
        }
    }
}
