
// Static strings
const openMenuClass = 'nav-menu-is-open';
const openSisterClass = 'sister-nav-expanded';
const closeSisterClass = 'sister-nav-collapsed';
const openQuickNavClass = 'quick-nav-expanded';
const closeQuickNavClass = 'quick-nav-collapsed';
const openSearchClass = 'nav-search-is-open';
const navId = "data-nav-container";
const subNavId = "utility-nav-container";
const menuId = "menu";
const searchId = "data-nav-search";
const anchorNav = "pageNav"
const sisterNavId = "sisterNav"

class MainNavigation {
    container: HTMLElement;
    menuTrigger: NodeListOf<HTMLElement>;
    searchTrigger: HTMLButtonElement;
    menuPanel: HTMLElement;
    searchContainer: HTMLElement;
    menuFocusableChildren: Array<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement>;
    menuIsOpen: boolean;
    searchIsOpen: boolean;
    navIsShowing: boolean;
    existingBodyStyles: string;
    lastScrollPosition: number;
    hasLoaded: boolean;
    currentNavHeight: number;
    bodyEl: HTMLBodyElement;
    onPageAnchorLinks: HTMLAnchorElement[];
    navSearchCloseBtn: null | HTMLElement;
    subnavDisabledPage: boolean;
    background: HTMLElement;
    innerNav: HTMLElement;
    triggers: HTMLElement[] | any;
    sisterNavTrigger: NodeListOf<HTMLElement>;
    quickNavTriggers: NodeListOf<HTMLElement>;
    sisterNav: HTMLElement;
    sisterIsOpen: boolean;
    quickNavIsOpen: boolean;
    constructor(navigation: HTMLElement) {
        this.container = navigation;
        this.menuTrigger = this.container.querySelectorAll(`[aria-controls="${menuId}"]`) as NodeListOf<HTMLElement>;
        this.searchTrigger = this.container.querySelector(`[data-search-toggle]`) as HTMLButtonElement;
        this.sisterNavTrigger = this.container.querySelectorAll(`[aria-controls="${sisterNavId}"]`) as NodeListOf<HTMLElement>;
        this.sisterNav = document.querySelector(`#${sisterNavId}`) as HTMLElement;
        this.quickNavTriggers = this.container.querySelectorAll(`[data-quick-nav-trigger]`) as NodeListOf<HTMLElement>;
        this.menuPanel = document.querySelector(`#${menuId}`) as HTMLElement;
        this.searchContainer = document.querySelector(`[${searchId}]`) as HTMLElement;
        this.menuFocusableChildren = [].slice.call(this.menuPanel.querySelectorAll('button, a, input'));
        this.bodyEl = document.body as HTMLBodyElement;
        this.menuIsOpen = false;
        this.sisterIsOpen = this.sisterNav.classList.contains(openSisterClass);
        this.quickNavIsOpen = false;
        this.searchIsOpen = false;
        this.navIsShowing = true;
        this.hasLoaded = false;
        this.existingBodyStyles = '';
        this.navSearchCloseBtn = null;
        this.subnavDisabledPage = false;
        this.background = document.querySelector('.primary-nav-subnav-container');
        this.innerNav = document.querySelector('.primary-nav');
        this.triggers = Array.from(document.querySelectorAll('[data-primary-nav-trigger]'));

        // Intercept on-page links so that the offset recalculates before the browser reaches it
        // This makes sure that the target is always comfortably visible below the nav
        this.onPageAnchorLinks = [].slice.call(document.querySelectorAll('a[href^="#"]'));
        this.onPageAnchorLinks.forEach(link => {
            link.addEventListener('click', (e: Event) => {
                e.preventDefault();
                const hash = link.getAttribute('href');
                const target = document.querySelector(hash) as HTMLElement;
                if (target) {
                    setTimeout(() => {
                        target.scrollIntoView(true);
                        target.focus();
                        window.location.hash = hash;
                    }, 0);
                }
            })
        })

    }

    init = () => {
        this.menuTrigger.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                if (this.menuIsOpen) {
                    this.closeMenu();
                }
                else {
                    if (this.searchIsOpen) {
                        this.closeSearch();
                    }
                    this.openMenu();
                }
            });
        });
        
        this.sisterNavTrigger.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                if (this.sisterIsOpen) {
                    this.closeSister();
                }
                else {
                    this.openSister();
                }
            });
        });

        this.quickNavTriggers.forEach((trigger) => {
            let isTouch = false;
            trigger.addEventListener('click', () => {
                if (this.quickNavIsOpen) {
                    this.closeQuickNav(trigger, isTouch);
                }
                else {
                    this.openQuickNav(trigger, isTouch);
                }
            });
            trigger.addEventListener('touchstart', (e) => {
                isTouch = true;
            });
        });
        this.searchTrigger.addEventListener('click', () => {
            if (this.searchIsOpen) {
                this.closeSearch();
            }
            else {
                if (this.menuIsOpen) {
                    this.closeMenu();
                }
                this.openSearch();
            }
        });

        this.triggers.forEach(trigger => {
            let isTouch = false;
            trigger.addEventListener('click', (e) => {
                if (trigger.classList.contains('trigger-enter') && trigger) {
                    this.handleLeave(trigger)
                }
                else {
                    this.handleEnter(trigger)
                }
            });
            trigger.addEventListener('touchstart', (e) => {
                isTouch = true;
            });
            trigger.addEventListener('mouseenter', (e) => {
                if (!trigger.classList.contains('trigger-enter') && !isTouch) {
                    let activeButton = document.querySelector('.trigger-enter-active');
                    if ( activeButton) {
                        this.handleLeave(activeButton);
                    }
                    
                }
                isTouch = false;
            });
        });

        document.body.addEventListener('click', (e) => {
            let activeButton = document.querySelector('.trigger-enter-active');
            let activeContainer = document.querySelector('[data-primary-nav][aria-expanded="true"]');
            if (activeButton !== e.target && activeContainer && !activeContainer.contains(e.target as HTMLElement) && activeButton) {
                this.handleLeave(activeButton);
            }
        });

        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.key == 'Escape' && this.menuIsOpen) { // Escape key
                this.closeMenu();
                this.menuTrigger[0].focus();
            }
            if (e.key == 'Tab') { // Tab key. Show nav if tabbed into, or close menu if tabbed out of.
                const focusInNav = this.container.contains(document.activeElement);

                this.triggers.forEach(trigger => {
                    if (trigger.parentElement.querySelector('[data-primary-nav]').contains(document.activeElement) || trigger.contains(document.activeElement)) {
                        this.handleEnter(trigger)   
                    }
                    else {
                        this.handleLeave(trigger)

                    }
                })
            }
        });
    }

    openMenu = () => {
        this.menuPanel.setAttribute('aria-hidden', 'false');
        this.menuTrigger.forEach((trigger) => {
            trigger.setAttribute('aria-expanded', 'true');
        });
        this.enableTabbableChildren();
        this.menuIsOpen = true;
        this.container.classList.add(openMenuClass);

    };


    closeMenu = () => {
        this.menuPanel.setAttribute('aria-hidden', 'true');
        this.menuTrigger.forEach((trigger) => {
            trigger.setAttribute('aria-expanded', 'false');
        });
        this.disableTabbableChildren();
        this.menuIsOpen = false;
        this.container.classList.remove(openMenuClass);
        let quicklinkmobile = document.querySelector('[data-quick-nav-links-mobile]');
        if (quicklinkmobile) {
            quicklinkmobile.classList.remove(openQuickNavClass);
            quicklinkmobile.classList.add(closeQuickNavClass)
        }
    }

    openSister = () => {
        this.sisterNav.setAttribute('aria-hidden', 'false');
        this.sisterNavTrigger.forEach((trigger) => {
            trigger.setAttribute('aria-expanded', 'true');
        });
        this.enableTabbableChildren();
        this.sisterIsOpen = true;
        this.sisterNav.classList.add(openSisterClass);
        this.sisterNav.classList.remove(closeSisterClass);

    };


    closeSister = () => {
        this.sisterNav.setAttribute('aria-hidden', 'true');
        this.sisterNavTrigger.forEach((trigger) => {
            trigger.setAttribute('aria-expanded', 'false');
        });
        this.disableTabbableChildren();
        this.sisterIsOpen = false;
        this.sisterNav.classList.remove(openSisterClass);
        this.sisterNav.classList.add(closeSisterClass);
    }

    openQuickNav = (quickNavTrigger, isTouch) => {
        this.quickNavIsOpen = true;
        let quicknavmenu = quickNavTrigger.parentNode.querySelector('[data-quick-nav-links]');
        if (quickNavTrigger.hasAttribute('data-quick-nav-trigger-mobile')) {
            quicknavmenu = document.querySelector('[data-quick-nav-links-mobile]');
        }
        quickNavTrigger.setAttribute('aria-expanded', 'true');
        this.enableTabbableChildren();
        
        quicknavmenu.classList.add(openQuickNavClass);
        quicknavmenu.classList.remove(closeQuickNavClass);
        setTimeout(() => {
            document.addEventListener('click', (e) => {
                let target = e.target as HTMLElement;
                if (target == quickNavTrigger || target.closest('[data-quick-nav-links]') || target.closest('[data-quick-nav-trigger]')) { return }
                else {
                    this.closeQuickNav(quickNavTrigger, isTouch);
                }
            }, { once: true });
        }, 0);

    };


    closeQuickNav = (quickNavTrigger, isTouch) => {
        this.quickNavIsOpen = false;
        this.quickNavTriggers.forEach((trigger) => {
            let quickNav = trigger.parentNode.querySelector('[data-quick-nav-links]');
            if (quickNavTrigger.hasAttribute('data-quick-nav-trigger-mobile')) {
                quickNav = document.querySelector('[data-quick-nav-links-mobile]');
            }
            trigger.setAttribute('aria-expanded', 'false');
            if (quickNav != null) {
                quickNav.classList.remove(openQuickNavClass);
            }
            if (isTouch && quickNav != null) {
                quickNav.classList.add(closeQuickNavClass);
            }
        });
        this.disableTabbableChildren();
    }

    openSearch = () => {
        this.searchContainer.setAttribute('aria-hidden', 'false');
        this.searchTrigger.setAttribute('aria-expanded', 'true');
        this.searchIsOpen = true;
        this.container.classList.add(openSearchClass);
    };

    closeSearch = () => {
        this.searchContainer.setAttribute('aria-hidden', 'true');
        this.searchTrigger.setAttribute('aria-expanded', 'false');
        this.searchIsOpen = false;
        this.container.classList.remove(openSearchClass);
    }

    enableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = 0;
        })
    }

    disableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = -1;
        })
    }

    handleEnter = (e) => {
        if (!this.searchIsOpen) {
            e.classList.add('trigger-enter');
            setTimeout(() => e.classList.contains('trigger-enter') && e.classList.add('trigger-enter-active'), 150);
            const dropdown = e.parentNode.querySelector('[data-primary-nav]');
            dropdown.setAttribute('aria-expanded', true)
        }
    }


    handleLeave(e) {
        if (!this.searchIsOpen) {
            e.classList.remove('trigger-enter', 'trigger-enter-active');
            const dropdown = e.parentNode.querySelector('[data-primary-nav]');
            dropdown.setAttribute('aria-expanded', false)

        }
    }


    get isTheMenuOpen() {
        return this.menuIsOpen;
    }
}

let mainNavigation: MainNavigation = null;
const navContainer = document.querySelector(`[${navId}]`) as HTMLElement;
if (navContainer) {
    mainNavigation = new MainNavigation(navContainer);
    (window as any).mainNavigation = mainNavigation;
}

export { mainNavigation };