import React from 'react';
import { htmlEncode } from './string-helpers';
import { isNullOrUndefined } from './data-helpers';

export const convertSymbols = (value: string, encodeInput: boolean = false, returnAsElement: boolean = true): string | React.JSX.Element => {
    if (isNullOrUndefined(value)) {
        return value;
    }
    let regex = /\u00AE/g;
    if (encodeInput) {
        value = htmlEncode(value);
    }
    
    if (value.match(/\u00AE/g)) {
        var newString = value.replace(/\u00AE/g, '<sup>&reg;</sup>');
        if (returnAsElement) {
            return <span dangerouslySetInnerHTML={{ __html: newString }}></span >;
        }
        return newString;
    }
    return value;
}

