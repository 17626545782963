import { isNullOrUndefined } from "./data-helpers";

export function htmlEncode(input: string) {
    if (!isNullOrUndefined(input)) {
        return input
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }
    return input;
}