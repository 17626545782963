
export const scrollToTarget = (targetElement: HTMLDivElement) => {
    const urlParams = new URLSearchParams(window.location.search);
    // don't scroll unless they have paged
    if (urlParams.has('page')) {
        // Get the maximum scroll amount minus some buffer so we don't hit the bottom of the page
        var scrollLimit = document.documentElement.scrollHeight - window.innerHeight - 5;
        // Get the minimum of the scroll limit and the top of the app element (minus some buffer)
        var yCoord = Math.min(scrollLimit, targetElement.getBoundingClientRect().top + window.scrollY - 5);
        window.scrollTo(0, yCoord);
    }

}