import * as React from 'react';


export interface ILoadingProps {
    loading: boolean;
}


export function LoadingSpinner({loading, ...rest}: ILoadingProps)   {

        if (loading)
            return null;
        return (
            <div className="loader">
                <div className="loader-circle">
                    <svg className="spin" viewBox="25 25 50 50">
                        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10" />
                    </svg>
                    <svg className="base" viewBox="25 25 50 50">
                        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10" />
                    </svg>
                </div>
            </div>
        );

}