import { enableTabbableChildren } from '../helpers/enableTabbableChildren';
import { disableTabbableChildren } from '../helpers/disableTabbableChildren';
import { getHeightOfHidden } from '../helpers/getHeightOfHidden';


var debounce = require('lodash.debounce');
var assign = require('lodash.assign');
export function bioBlock(bioBlocks) {

    bioBlocks.forEach(bioBlock => {
        let bioBlockButton = bioBlock.querySelector('[data-bio-block-button]');

        let bioBlockCollapse = bioBlock.querySelector('[data-bio-block-collapse]');
        let bioBlockContent = bioBlock.querySelector('[data-bio-block-count]');
        let bioBlockItems = bioBlockContent.querySelectorAll('.bio-block-item') as NodeListOf<HTMLElement>;
        let bioBlockMax = Math.floor(bioBlockContent.getAttribute('data-bio-block-max'));

        bioBlockItems.forEach((item, index) => {
            if (index < bioBlockMax) {
                item.style.display = 'block';
            }
        });
        if (bioBlockItems.length < (bioBlockMax + 1)) {
            if (bioBlockButton) {
                bioBlockButton.style.display = 'none';
            }
        }

        bioBlockButton.addEventListener('click', (e) => {

            let target = e.currentTarget as HTMLElement;
            let container = target.closest('[data-bio-block]') as HTMLElement;
            let totalBioBlockCount = getBioBlockCount(container);
            let hiddenBioBlockCount = getBioBlockHiddenCount(container);
            let currentlyShowing = totalBioBlockCount - hiddenBioBlockCount;
            if (totalBioBlockCount > currentlyShowing) {
                bioBlockItems.forEach((item, index) => {
                    if ((index + 1) > currentlyShowing && index < (currentlyShowing + bioBlockMax)) {
                        item.style.display = 'block';
                        if ((index + 1) == totalBioBlockCount) {
                            target.style.display = 'none';
                            target.ariaDisabled = 'true';
                            bioBlockCollapse.style.display = 'block';
                            bioBlockCollapse.ariaDisabled = 'false';
                        }
                    }
                });
            }
        });
        bioBlockCollapse.addEventListener('click', (e) => {
            bioBlockItems.forEach((item, index) => {
                if ((index + 1) > bioBlockMax) {
                    item.style.display = 'none';

                }
            });
            bioBlockButton.style.display = 'block';
            bioBlockButton.ariaDisabled = 'false';
            bioBlockCollapse.style.display = 'none';
            bioBlockCollapse.ariaDisabled = 'true';
            bioBlock.scrollIntoView({ behavior: 'smooth' });
        });
    });
}

function getBioBlockCount(container: HTMLElement) {
    let count = 0;
    count = container.querySelectorAll('.bio-block-item').length;

    return count;
}

function getBioBlockHiddenCount(container: HTMLElement) {
    let currentCount = Array.from(container.querySelectorAll('.bio-block-item') as NodeListOf<HTMLElement>).filter((el) => el.style.display == 'none').length;

    return currentCount;
}